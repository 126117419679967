<template>
  <div id="features" class="featured-container my-4">
      
      <h1 class="mb-3">
        Features
      </h1>

      <div class="w-100 d-flex justify-content-between mb-2 features-container" style="flex-wrap: wrap">
          <ServiceCard 
            v-for="service of services.slice(0,3)"
            :key="service.title"
            :title="service.title" 
            :content="service.content" 
            :icon="service.icon"
          />
      </div>

      <div class="w-100 d-flex justify-content-between features-container">
         <ServiceCard 
            v-for="service of services.slice(3)"
            :key="service.title"
            :title="service.title" 
            :content="service.content" 
            :icon="service.icon"
          />
      </div>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue"
import ServiceCard from "../shared/ServiceCard.vue";

export default {
    components: {
        BCol, 
        BRow,
        ServiceCard
    },
    data() {
      return {
        services: [
          {
            title: "Cash in 24 hours",
            content: "Get access to funds within<br /> 24 hours of application",
            icon: "QuickProcessing"
          },
          {
            title: "Simple signup process",
            content: "Avoid the hassle of filling long <br /> forms, sign up in a few easy steps.",
            icon: "simple"
          },
          {
            title: "No collateral/guarantors",
            content: "No collateral or guarantors are needed <br /> when you need to apply for funds.",
            icon: "property"
          },
          {
            title: "Short term loan",
            content: "Choose to repay your loan<br /> within 10 months",
            icon: "repayment"
          },
          {
            title: "Flexibility",
            content: `Top-up option available for existing <br />
            loans if you have not requested above <br />
            the maximum amount available.`,
            icon: "flexible"
          },
          {
            title: "Repayment",
            content: `Monthly repayment of loans are <br />
                automatic, via Ezwich through your NSS <br />
                Allowance.`,
            icon: "money"
          }
        ]
      }
    }
}
</script>

<style lang="scss" scoped>

.featured-container {
    padding-left: 11rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 400px; 
    justify-content: flex-start;

    @media (max-width: 768px) {
        padding-left: 7px;
        margin-left: 7px;
        margin-right: auto;
        justify-content: center;
        align-items: center;
    }
}

.feature-icon {
    display: flex;
    justify-content: center;
}

.features-container {
  @media (max-width: 768px) {
        flex-direction: column;
    }
}

</style>
